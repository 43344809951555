@import 'abstracts/functions';
@import 'abstracts/variables';

// 2. Vendors
@import 'vendors/bootstrap-utilities';
@import 'abstracts/rebuilded-mixins';
@import 'vendors-extensions/bootstrapFvs';
@import 'vendors/bootstrap';
@import "variables-bootstrap-pre-fvs";

@font-face {
  font-family: 'Big Vesta';
  src: local(''),
  url('#{$base-font-path}/Big_Vesta_light_italic.woff2') format('woff2'),
  url('.#{$base-font-path}/Big_Vesta_light_italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Big Vesta';
  src: local(''),
  url('#{$base-font-path}/Big_Vesta_light.woff2') format('woff2'),
  url('#{$base-font-path}/Big_Vesta_light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Vesta';
  src: local(''),
  url('#{$base-font-path}/Big_Vesta_regular.woff2') format('woff2'),
  url('#{$base-font-path}/Big_Vesta_regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Big Vesta';
  src: local(''),
  url('#{$base-font-path}/Big_Vesta_bold.woff2') format('woff2'),
  url('#{$base-font-path}/Big_Vesta_bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


body {
  font-size: 1rem;
  font-family: 'Big Vesta', sans-serif;
  background-color: transparent;
}

.body-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 502px;
    height: auto;
    opacity: .75;
    transition: opacity .3s ease-in-out;

    @include media-breakpoint-up(sm) {
      width: 715px;
      top: 121px;
    }

    @include media-breakpoint-up(md) {
      width: 1672px;
      top: 121px;
    }

    @include media-breakpoint-up(lg) {
      width: 1429px;
    }

    @include media-breakpoint-up(xl) {
      width: 1475px;
    }

    @media screen and (min-width: 1920px) {
      width: 1838px;
    }
  }
}

body.open-modal,
body.subpage {
  .body-bg {
    img {
      opacity: .25;
    }
  }
}

.unvisible {
  opacity: 0;
}

.header {
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 171px;
    height: auto;

    &-wrapper {
      position: absolute;
      left: calc(50% - 16px);
    }

    @include media-breakpoint-up(sm) {
      width: 270px;

      &-wrapper {
        left: calc(50% - 27px);
      }
    }

    @include media-breakpoint-up(md) {
      width: 297px;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 145px;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 289px;
    }

    @include media-breakpoint-up(xxl) {
      margin-right: 429px;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-top: 56px;
    padding-bottom: 48px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 72px;
  }
}

.container {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 640px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 864px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1150px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1432px;
  }
}

main {
  padding-top: 32px;
  min-height: calc(100vh - 96px - 187px);

  @include media-breakpoint-up(sm) {
    min-height: calc(100vh - 135px);
  }
}

p {
  font-size: rem(14px);
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: rem(22px);

  @include media-breakpoint-up(sm) {
    font-size: rem(14px);
    margin-bottom: rem(24px);
  }
}

h3 {
  font-size: rem(16px);
  font-weight: 700;
  margin-bottom: rem(18px);
}

h1 {
  color: #000;
  /* H1/Mobile */
  font-size: rem(32px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 341px;

  @include media-breakpoint-up(sm) {
    margin-bottom: rem(40px);
  }

  @include media-breakpoint-up(lg) {
    font-size: rem(48px);
    margin-bottom: rem(56px);
    max-width: 472px;
  }

  @include media-breakpoint-up(xl) {
    font-size: rem(56px);
    max-width: 684px;
  }
}

.start-view {
  padding-bottom: rem(48px);
  opacity: 0;
  transition: opacity .3s ease-in;

  @include media-breakpoint-up(sm) {
    padding-bottom: 160px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 24px;
  }

  &__button {
    border: 1px solid #C8C8C8;
    background-color: #fff;
    border-radius: 8px;
    height: 48px;
    padding: 0px 20px 0px 16px;
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    transition: all .3s ease-out;

    &:hover {
      border-color: #002F6A;
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: rem(24px);
    }

    svg {
      margin-right: 12px;
    }
  }
}

.footer {
  background: linear-gradient(-45deg, #001a3b, #002f6a);
  padding: 2rem 0;

  @include media-breakpoint-up(sm) {
    padding-top: 59px;
    padding-bottom: 43px;
  }

  &__inner {
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__nav {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(40px);

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }

    li {
      line-height: 1;
    }

    &-link {
      font-family: 'Big Vesta';
      font-weight: 300;
      color: #fff;
      font-size: rem(12px);
      font-style: normal;
      font-weight: 300;
      line-height: rem(17px);
      margin-right: rem(40px);
      margin-bottom: 1rem;
      white-space: nowrap;
      display: block;
      transition: color .35s cubic-bezier(0.3, 0.86, 0.36, 0.95);

      &:hover {
        color: #C3AA69;
        text-decoration: underline;
      }

      @include media-breakpoint-up(sm) {
        margin-right: rem(24px);
      }

      @include media-breakpoint-up(lg) {
        margin-right: rem(40px);
      }
    }
  }

  &__copyright {
    font-size: rem(12px);
    font-style: normal;
    font-weight: 300;
    line-height: rem(17px);
    margin-bottom: 0;
    color: #fff;
  }
}

.back-button {
 color: black;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  background-color: transparent;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  svg {
    margin-right: 4px;
  }
}

.country-modal {
  padding-bottom: 64px;
  opacity: 0;
  transition: opacity 0.3s ease-in;

  @include media-breakpoint-up(sm) {
    padding-bottom: 80px;
  }

  &__title {
    font-size: rem(32px);
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    display: flex;
    align-items: center;
    margin-bottom: rem(40px);

    svg {
      width: rem(32px);
      height: auto;
      margin-right: rem(20px);
    }

    @include media-breakpoint-up(lg) {
      font-size: rem(40px);
    }
  }

  &__text {
    color: #3f3f3f;
    font-size: rem(14px);
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
  }

  &__subtitle {
    color: #3f3f3f;
  }

  &__link {
    width: 100%;
    display: block;
    padding: 12px 32px;
    background-color: #002F6A;
    color: #fff;
    text-align: center;
    border-radius: 24px;
    margin-top: 24px;
    transition: all 0.3s ease-out;

    &:hover {
      color: #fff;
      text-decoration: none;
      border-color: #001938;
      background-color: #001938;
    }

    @include media-breakpoint-up(sm) {
      width: initial;
      display: inline-block;
      margin-top: 0;
    }
  }

  &__confirm-text {
    font-size: rem(14px);
    font-weight: 700;
    margin-bottom: 1.375rem;

    @include media-breakpoint-up(sm) {
      font-size: rem(16px);
      margin-bottom: 1.5rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.heaedr__back-wrapper {
  min-height: 19px;
}

hr {
  border-top-color: rgba(0, 47, 106, 0.50);
  margin-top: rem(40px);
  margin-bottom: rem(40px);
}

strong {
  font-weight: 700;
}


@include media-breakpoint-up(sm) {
  .header {
    position: absolute;
  }

  main {
    padding-top: 275px;
  }

  body:not(.open-modal) main {
    min-height: calc(100vh - 135px);
    padding-top: 186px;
    padding-bottom: 160px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .start-view {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include media-breakpoint-up(md) {
  .header {
    position: absolute;
  }

  main {
    padding-top: 191px;
  }

  body:not(.open-modal) main {
    min-height: calc(100vh - 135px);
    padding-top: 215px;
    padding-bottom: 184px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .start-view {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include media-breakpoint-up(lg) {
  .header {
    position: absolute;
  }

  main {
    padding-top: 191px;
  }

  body:not(.open-modal) main {
    min-height: calc(100vh - 135px);
    padding-top: 215px;
    padding-bottom: 184px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .start-view {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@include media-breakpoint-up(lg) {
  .header {
    position: absolute;
  }

  main {
    padding-top: 207px;
  }

  body:not(.open-modal) main {
    min-height: calc(100vh - 135px);
    padding-top: 287px;
    padding-bottom: 260px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .start-view {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.opacity-1 {
  opacity: 1;
}

.conatiner {
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 1300px;
}

.row > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
