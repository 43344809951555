// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff;
$black: #000;

$gray-100: #f8f9fa !default;
$gray-200: #e6e6e6;
$gray-300: #FAFAFA;
$gray-400: #C8C8C8;
$gray-500: #969696;
$gray-600: #707070;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$grays: (
);
$grays: map-merge(("100": $gray-100,
      "200": $gray-200,
      "300": $gray-300,
      "400": $gray-400,
      "500": $gray-500,
      "600": $gray-600,
      "700": $gray-700,
      "800": $gray-800,
      "900": $gray-900),
    $grays);

$blue: $fvs-blue;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: $fvs-red;
$orange: #C3AA69;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #82B4BE;
$brown: #7B4D55;
$brown645: #64505A;
$brown7d5: #7D5564;
$gold: #C3AA69;

$colors: (
);
$colors: map-merge(("blue": $blue,
      "indigo": $indigo,
      "purple": $purple,
      "pink": $pink,
      "red": $red,
      "orange": $orange,
      "yellow": $yellow,
      "green": $green,
      "teal": $teal,
      "cyan": $cyan,
      "white": $white,
      "gray": $gray-500,
      "brown": $brown,
      "gray-dark": $gray-800,
      "gold": $gold),
    $colors);

$primary: $blue;
$secondary: $gray-300;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-500;

$theme-colors: (
);
$theme-colors: map-merge(("primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "light": $light,
      "dark": $dark,
      "orange": $orange,
      "white": $white,
      "black": $black),
    $theme-colors);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 180;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $black;
$yiq-text-light: $white;

$grid-columns: 12;
$grid-gutter-width: 32px;

$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  wide: 1920px,
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: false;

// spacing
//
// control the default styling of most bootstrap elements by modifying these
// variables. mostly focused on spacing.
// you can add more entries to the $spacers map, should you need more variation.

// stylelint-disable-next-line scss/dollar-variable-default
// prettier-ignore-start
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * 0.4),
  // 4px
  2: ($spacer * 0.8),
  // 8px
  3: ($spacer * 1.2),
  // 12px
  4: ($spacer * 1.6),
  // 16px
  5: ($spacer * 2.0),
  // 20px
  6: ($spacer * 2.4),
  // 24px
  7: ($spacer * 2.8),
  // 28px
  8: ($spacer * 3.2),
  // 32px
  10: $spacer,
  // todo: to chek, if it is in use
  12: ($spacer * 4.8),
  // 48px
  14: ($spacer * 5.6),
  // 56px
  15: ($spacer * 1.5),
  // todo: to chek, if it is in use
  16: ($spacer * 6.4),
  // 64px
  20: ($spacer * 8.0),
  // 80px
  22: ($spacer * 2.2),
  // todo: to chek, if it is in use
  24: ($spacer * 9.6),
  // 96px
  25: ($spacer * 2.5),
  // todo: to chek, if it is in use
  28: ($spacer * 11.2),
  // 112px
  30: ($spacer * 3),
  // todo: to chek, if it is in use
  34: ($spacer * 13.6),
  // 136px
  35: ($spacer * 3.5),
  // todo: to chek, if it is in use
  40: ($spacer * 4),
  // todo: to chek, if it is in use
  43: ($spacer * 4.3),
  // todo: to chek, if it is in use
  45: ($spacer * 4.5),
  // todo: to chek, if it is in use
  48: ($spacer * 4.8),
  //48px
  50: ($spacer * 5),
  // todo: to chek, if it is in use
  60: ($spacer * 6),
  // todo: to chek, if it is in use
  61: ($spacer * 6.1),
  // todo: to chek, if it is in use
  64: ($spacer * 6.4),
  // todo: to chek, if it is in use
  66: ($spacer * 6.6),
  // todo: to chek, if it is in use
  70: ($spacer * 7),
  // todo: to chek, if it is in use
  74: ($spacer * 29.6),
  // 296px
  75: ($spacer * 7.5),
  // todo: to chek, if it is in use
  80: ($spacer * 8),
  // todo: to chek, if it is in use
  90: ($spacer * 9),
  // todo: to chek, if it is in use
  92: ($spacer * 9.2),
  // todo: to chek, if it is in use
  96: ($spacer * 9.6)
); // todo: to chek, if it is in use


// body
//
// settings for the `<body>` element.

$body-bg: $white;
$body-color: $black;


// border radius
$rounded-circle: 50%;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Big Vesta Regular",
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  sans-serif;
$font-family-sans-serif-secondary: $font-family-sans-serif;
$font-family-monospace: SFMono-Regular,
  Menlo,
  Monaco,
  Consolas,
  "Liberation Mono",
  "Courier New",
  monospace;
$font-family-base: $font-family-sans-serif;
$base-font-path: '../fonts';
// stylelint-enable value-keyword-case

// $enable-print-styles:                         false;

$rem-base: 10px;

$font-weight-thin: 100;
$font-weight-ulight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;
$font-weight-black: 900;

$font-size-xl: 2rem;
$font-size-lg: 1.8rem;
$font-size-base: 1.6rem;
$font-size-md: 1.4rem;
$font-size-sm: 1.2rem;


$font-sizes: (
  'xl': $font-size-xl,
  'lg': $font-size-lg,
  'base': $font-size-base,
  'md': $font-size-md,
  'sm': $font-size-sm,
);

$font-sizes-max: (
  'xl': 3rem,
  'lg': 2.2rem,
  'base': 1.6rem,
  'md': 1.4rem,
  'sm': 1.2rem,
);

$headings-sizes: (
  'h1': 5.6rem,
  'h2': 4rem,
  'h3': 3.2rem,
  'h4': 2.4rem,
  'h5': 1.8rem,
  'h6': 1.6rem,
);

$headings-sizes-max: (
  'h1': 5.6rem,
  'h2': 4rem,
  'h3': 3.2rem,
  'h4': 2.4rem,
  'h5': 1.8rem,
  'h6': 2.6rem,
);


$display1-size: 56px;
$display1-weight: 400;


// Links
//
// Style anchor elements.

$link-color: theme-color("primary");
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 2.4rem;


// Headings
$h1-font-size: map-get($headings-sizes, 'h1');
$h2-font-size: map-get($headings-sizes, 'h2');
$h3-font-size: map-get($headings-sizes, 'h3');
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: $paragraph-margin-bottom;
$headings-font-family: null;
$headings-font-weight: 300;
$headings-line-height: 1.5;
$headings-color: null;

$fluid-min: 320px;
$fluid-max: map-get($grid-breakpoints, 'wide');

// Base transition function
$transition-duration-base: 0.35s;
$transition-easing-base: cubic-bezier(0.3, 0.86, 0.36, 0.95);


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px;
$border-color: $gray-300 !default;

$border-radius: 1rem;
$border-radius-lg: 1.5rem;
$border-radius-xl: 2rem;
$border-radius-xxl: 3rem;
$border-radius-sm: .2rem !default;

$rounded-pill: 50rem !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 12px;
$input-btn-padding-x: 32px;
$input-btn-font-family: $font-family-sans-serif;
$input-btn-font-size: $text-body-font-size;
$input-btn-line-height: $text-line-height-body;

$input-btn-focus-width: .2rem !default;
$input-btn-focus-color: rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-border-width: $border-width;

$input-btn-padding-y-sm: .25rem;
$input-btn-padding-x-sm: 1.5rem;
$input-btn-font-size-sm: $text-body-font-size;
$input-btn-line-height-sm: $text-line-height-body;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-bold;
$btn-box-shadow: inset 0 1px 0 rgba($white, .15),
  0 1px 1px rgba($black, .075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: .65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius-xl;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: 1.2rem;

$btn-transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out !default;

// Breadcrumbs

$breadcrumb-font-size: 1.2rem;

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 1.2rem;

$breadcrumb-margin-bottom: 0;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: currentColor;
$breadcrumb-active-color: currentColor;

$breadcrumb-border-radius: 0;


// Cards

$card-spacer-y: .75rem;
$card-spacer-x: 1.25rem;
$card-border-width: null;
$card-border-radius: $border-radius;
$card-border-color: null;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-color: $black;
$card-bg: $white;


// Forms

$label-margin-bottom: .5rem !default;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-bg: transparent;
$input-disabled-bg: $gray-200 !default;

$input-color: inherit;
$input-border-color: rgba($white, 0.3);
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius: 0;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg;
$input-focus-color: $input-color;
$input-focus-border-color: $input-focus-color;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: rgba($black, 0.3);
$input-plaintext-color: $body-color !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: .5rem !default;

$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: $white;
$modal-content-bg: transparent;
$modal-content-border-color: rgba($black, .2) !default;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: 0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $border-color !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: 1rem !default;
$modal-header-padding-x: 1rem !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 1210px;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;
