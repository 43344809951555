/*
Bootstrap variable overrides
*/
// Toggle global options
$enable-gradients: true;
$enable-shadows: false;

// Add a breakpoint
$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1920px //todo: remove this breakpoint, does not exist in Bootstrap 4?
);


/*------------------------------------*\
    #Grid container
\*------------------------------------*/
$container-sm: 100%;

$container-max-widths: (
  sm: $container-sm,
  md: $container-md,
  lg: $container-lg,
  xl: $container-xl
);


//typography
$font-family-sans-serif: $font-family-regular;

//colors
$green: $fvs-turquoise;
$red-light: $fvs-red-light;
$gray-100: #f8f9fa;
$gray-200: #e5e5e7;
$gray-600: #969696;

//more colors, merged with bootstraps default theme colors.
$gold: $fvs-gold;
$theme-colors: (
  "gold": $gold
);

//borders
$border-radius: 0.8rem;
$border-radius-lg: 1.2rem;

//buttons
$btn-border-radius: 99em;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;
$btn-padding-x: 2em;
$btn-padding-x-sm: 2em;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-focus-width: 0;
$btn-border-width: .5px;

//headings
$headings-font-weight: normal;
$headings-margin-bottom: 1em;

//$line-height-base: $line-height-body; // 24px

$modal-fade-transform: none;
//forms
$form-check-input-margin-y: 0.55rem;

$font-size-base: 1rem;
