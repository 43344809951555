// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels


/*------------------------------------*\
    #Grid container
\*------------------------------------*/
$container-sm: 540px;
$container-md: 672px;
$container-lg: 864px;
$container-xl: 1400px;


$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'laptop': 1366px,
  'widescreen': 1800px
);

/*------------------------------------*\
    #Grid container
\*------------------------------------*/
$container-max-widths: (
  sm: $container-sm,
  md: $container-md,
  lg: $container-lg,
  xl: $container-xl
);


// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);


// -----------------------------------------------------------------------------
// SCSS variables NOT defined by bootstrap. Custom variables might use bootstrap variables.
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// CORNER RADIUS
// -----------------------------------------------------------------------------

$corner-radius-sm: 8px;
$corner-radius-md: 16px;
$corner-radius-lg: 100px;


// -----------------------------------------------------------------------------
// SPACING
// -----------------------------------------------------------------------------
$service-nav-toggle-gap-right: calc(3rem * 1.6);
$service-nav-toggle-gap-bottom: calc(6rem * 1.6);

// $margin-lg: map-get($spacers, 6);
$margin-fw-rounded: 1rem;
$teaser-card-gap: calc(0.75rem * 1.6);

/*Colors, gradients, content*/
$gradient-fsv-one-blueish-gray: linear-gradient(90deg, #67738e 15%, #6b7a92 41%, #768e9f 80%, #7e9ca8 100%);
$gradient-fsv-dark-blue: linear-gradient(120deg, #002f6a 0%, #002c65 28%, #002657 59%, #001c41 93%, #001a3b 100%);

$fvs-gradient-blue-accent: linear-gradient(335.93deg, #001A3B 3.02%, #002F6A 41.62%);
$fvs-gradient-blue-light-accent: linear-gradient(0.17deg, #45627A 8.09%, #72AAB6 67.12%);
$fvs-gradient-red-accent: linear-gradient(0deg, #734F61 11.65%, #9B536E 93.29%);

$fvs-gradient-multiasset: linear-gradient(327.22deg, #001A3B 3.83%, #002F6A 92.46%);


/** Gradient */
$button-gradient: transparent linear-gradient(0deg, #ABABAB 0%, #B4B4B4 4%, #D2D2D2 17%, #E8E8E8 29%, #F5F5F5 41%, #FAFAFA 53%) 0% 0% no-repeat padding-box;


/*
To do: Height of the header is caused by nested padding.
Clean up the header CSS or maybe add this as min-height on desktop.
*/
$fixed-header-height: calc(9rem * 1.6);
$padding-12: 48px;

// -----------------------------------------------------------------------------
// #TYPOGRAPHY -> FONT-SIZES AND LINE-HEIGHTS
// -----------------------------------------------------------------------------
$font-family-regular: 'Big Vesta Regular';
$font-family-light: 'Big Vesta Light';
$font-size-huge: calc(5rem * 1.6);

$text-4xl-font-size: 56px;
$text-3xl-font-size: 48px;
$text-2xl-font-size: 40px;
$text-xl-font-size: 32px;
$text-lg-font-size: 24px;
$text-md-font-size: 18px;
$text-sm-font-size: 14px;
$text-xs-font-size: 12px;
$text-body-font-size: 16px;

$text-line-height-4xl: 80px;
$text-line-height-3xl: 64px;
$text-line-height-2xl: 56px;
$text-line-height-xl: 40px;
$text-line-height-lg: 32px;
$text-line-height-md: 24px;
$text-line-height-sm: 24px;
$text-line-height-xs: 18px;
$text-line-height-body: 24px;


// -----------------------------------------------------------------------------
// #GLOBAL COLORS
// -----------------------------------------------------------------------------
$black: #000000;
$white: #FFFFFF;

$fvs-red: #B51D28;
$fvs-red-negative: #E32E37;
$fvs-red-light: #B9826E;
$fvs-blue: #002F6A;
$fvs-blue-light: #82B4BE;
$fvs-gold: #C3AA69;
$fvs-gray-bright: #FAFAFA;
$fvs-gray-light: #E6E6E6;
$fvs-gray-middle: #C8C8C8;
$fvs-gray-hover: #F5F5F5;
$fvs-gray-dark: #969696;
$fvs-turquoise: #00DCC8;
$fvs-green-positive: #008C6F;
$fvs-green-dark: #005A5A;

// -----------------------------------------------------------------------------
// #Fvs ONE COLORS
// -----------------------------------------------------------------------------
$fvs-blue-one: #7E9CA8;
$fvs-blue-one-dark: #5D6983;


// -----------------------------------------------------------------------------
// #PRIMARY COLORS
// -----------------------------------------------------------------------------
$primary-blue: $fvs-blue;
$primary-turquoise: $fvs-turquoise;


// -----------------------------------------------------------------------------
// #BACKGROUND COLORS
// -----------------------------------------------------------------------------
$background-white: $white;
$background-darkgrey: $fvs-gray-dark;
$background-grey: #E1E1E1;
$background-lightgrey: $fvs-gray-bright;
$background-blue: $fvs-blue;

// -----------------------------------------------------------------------------
// #BUTTON COLORS
// -----------------------------------------------------------------------------
$button-blue: $fvs-blue;
$button-blue-dark: #001938;