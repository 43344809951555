//
// @functions
//

// Color contrast
@function color-yiq($color, $dark: $yiq-text-dark, $light: $yiq-text-light) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= $yiq-contrasted-threshold) {
    @return $dark;
  } @else {
    @return $light;
  }
}

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// CONVERT TO REM
// sass-lint:disable zero-unit
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0) {
    $value: 0;
  } // Turn 0rem into 0
  @return $value;
}

@function calc-vw($number) {
  @return ($number * 100vw) / 1920;
}

// REM CALC

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of rem's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported:
//
//   rem-calc(10 20 30px 40);
//
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
//
//   rem-calc((10, 20, 30, 40px));
//
// Optionally call with a different base (eg: 8px) to calculate rem.
//
//   rem-calc(16px 32px 48px, 8px);
//
// If you require to comma separate your list
//
//   rem-calc((16px, 32px, 48), 8px);

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $rem-values: ();
  @for $i from 1 through $max {
    $rem-values: append($rem-values, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $rem-values;
}

@function em-calc($values, $base-value: $rem-base) {
  $rem-values: rem-calc($values, $base-value: $rem-base);

  $max: length($rem-values);

  @if $max == 1 {
    @return strip-unit(nth($rem-values, 1)) * 1em;
  }

  $em-values: ();
  @for $i from 1 through $max {
    $em-values: append($em-values, strip-unit(nth($rem-values, $i)) * 1em);
  }
  @return $em-values;
}

/// colors black & white
/// @example scss - Usage
///   .my-class {
///     background: black(0.15);
///     color: white(0.9);
///   }
@function black($opacity) {
  @return rgba(black, $opacity);
}

@function white($opacity) {
  @return rgba(white, $opacity);
}

@function rem($pxValue) {
  @return $pxValue / 16px * 1rem;
}
